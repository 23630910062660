/** @jsx jsx */
import { useEffect, useRef, useState } from "react";
import { jsx, css } from "@emotion/core";
import { googleLoginSuccessCallback, googleLoginErrorCallback, redirectToGoogleLogin } from "../../helpers/misc";
import { GoogleIcon } from "../ui/icons/GoogleIcon";
import { EmailIcon } from "../ui/icons/EmailIcon";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
import { PaytmLoginIcon } from "../ui/icons/PaytmLoginIcon";
const LoginContainerStyle = css({
    display: "flex",
    flexFlow: "column"
});
const LoginBoxCss = {
    borderRadius: "6px",
    border: "solid 0.5px #dddfe2",
    height: "48px",
    margin: "auto 0 12px 0",
    display: "flex",
    alignItems: "center"
};
const LoginIconCss = css({
    margin: "0 9px"
});
const LoginBoxTextCss = css({
    fontSize: "14px",
    lineHeight: "1.31",
    color: "#676465"
});
const LoginBox = ({ style, icon, onClick, children }) => (<div className={`login-bar`} css={css(style)} onClick={onClick}>
		<div className={`login-icon`} css={LoginIconCss}>
			{icon}
		</div>
		{children}
	</div>);
export const MobileMenuLogin = (props) => {
    const [loginError, setLoginError] = useState("");
    const isMounted = useRef(true);
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    });
    const redirectUrl = window.location.pathname;
    const context = "mobile-menu";
    const setLoginErrorWithCheck = (err) => isMounted.current && setLoginError(err);
    const clearLoginError = () => setLoginErrorWithCheck("");
    const onSuccess = (userData) => {
        clearLoginError();
        props.setUserLoginData(userData);
    };
    const onGoogleLoginSuccess = (response) => {
        return googleLoginSuccessCallback(response, setLoginErrorWithCheck, onSuccess, context);
    };
    const onGoogleLoginError = (response) => {
        return googleLoginErrorCallback(response, setLoginErrorWithCheck, context);
    };
    const openPaytmLoginModal = () => {
        props.setModalScreen("paytmLoginScreen");
        props.setModalType({
            modalType: "login",
            context
        });
    };
    return (<div className={`mobile-login-container`} css={LoginContainerStyle}>

			{(!props.disableSocialLogin && !props.isSamsungDevice) && <div><LoginBox style={LoginBoxCss} icon={<GoogleIcon />} onClick={() => redirectToGoogleLogin(redirectUrl)}>
				<div className={`login-text`} css={LoginBoxTextCss}>
					Sign in with Google
				</div>
			</LoginBox>
			{!props.isSamsungDevice && <LoginBox style={LoginBoxCss} icon={<PaytmLoginIcon />} onClick={() => openPaytmLoginModal()}>
				<div className={`login-text`} css={LoginBoxTextCss}>
					Sign in with Paytm
				</div>
			</LoginBox>}
			</div>}
			<InsiderLink className={`login-text`} css={LoginBoxTextCss} href={"/users/email-login"} onClick={props.disableMobileMenu}>
				<LoginBox style={LoginBoxCss} icon={<EmailIcon />}>
					Enter your email address
				</LoginBox>
			</InsiderLink>
		</div>);
};
