import React from "react";
export class GoogleIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<svg height={24} width={24} style={{ display: `block` }}>
				<g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
					<path d="M10.23 3.955c1.92 0 3.216.83 3.955 1.523l2.887-2.818C15.299 1.012 12.992 0 10.23 0 6.229 0 2.773 2.296 1.09 5.638L4.4 8.206c.83-2.466 3.125-4.25 5.83-4.25z" fill="#ea4335"/>
					<path d="M20.05 10.457c0-.841-.068-1.455-.216-2.092H10.23v3.797h5.637c-.114.943-.727 2.364-2.091 3.319l3.228 2.5c1.932-1.784 3.046-4.41 3.046-7.524z" fill="#4285f4"/>
					<path d="M4.41 12.253a6.297 6.297 0 0 1-.34-2.023c0-.705.124-1.387.329-2.024L1.09 5.638A10.238 10.238 0 0 0 0 10.23c0 1.648.398 3.205 1.091 4.591z" fill="#fbbc05"/>
					<path d="M10.23 20.459c2.761 0 5.08-.91 6.774-2.478l-3.228-2.5c-.864.602-2.023 1.023-3.546 1.023-2.706 0-5.002-1.785-5.82-4.251L1.103 14.82c1.682 3.342 5.126 5.638 9.127 5.638z" fill="#34a853"/>
					<path d="M0 0h20.459v20.459H0z"/>
				</g>
			</svg>);
    }
}
