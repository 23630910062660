import React from "react";
export class EmailIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<svg height={24} width={24} style={{ display: `block` }}>
				<path d="M23 7.982a1 1 0 0 1 0 .034V18a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V8.017a1 1 0 0 1 0-.035V6a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3zm-2-.547V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1.433l9.027 5.4L21 7.436zm0 2.334l-8.456 5.088a1 1 0 0 1-1.029.001L3 9.764V18a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1z" fill="#373435"/>
			</svg>);
    }
}
