/** @jsx jsx */
// @ts-ignore
import { Fragment } from "react";
import { jsx, css } from "@emotion/core";
import InsiderLink from "../../../containers/InsiderLinkWithSubscription";
const UserInfoMobile = (props) => {
    if (props.isLoggedIn) {
        return (<Fragment>
				<InsiderLink href={`/users/me/profile`} onClick={props.disableMobileMenu}>
					<div className={`user-full-name`} css={css `
							font-size: 25px;
							font-weight: 900;
							line-height: 1.12;
							letter-spacing: -0.2px;
							color: #373435;
						`}>
						{props.displayName}
					</div>
					<div className={`user-email`} css={css `
							margin-top: 3px;
							font-size: 14px;
							line-height: 1.29;
							letter-spacing: -0.3px;
							color: #676465;
						`}>
						{props.email}
					</div>
				</InsiderLink>
			</Fragment>);
    }
    else
        return null;
};
export default UserInfoMobile;
