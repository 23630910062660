/** @jsx jsx */
import React, { Fragment } from "react";
import { jsx, css, keyframes } from "@emotion/core";
import { MobileMenuProfile } from "./MobileMenuProfile";
import { EVENT_CATEGORIES, GENERAL_ACTIONS, HIT_TYPES } from "../../helpers/constants";
import { CSSTransition } from "react-transition-group/dist/react-transition-group";
import theme from "../../styles";
import MobileMenuFooter from "./MobileMenuFooter";
import { MobileMenuHeader } from "./MobileMenuHeader";
import { sendClevertapEvent } from "../../helpers/misc";
import { mediumDevice } from "../../styles/common";
const slideRight = keyframes `
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
`;
const slideLeft = keyframes `
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
`;
const MobileMenuOverlayStyles = `
	display: block;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 120;

	@media screen and (max-width: ${mediumDevice}em) {
		height: auto;
		width: 90%;
		box-shadow: 0 0 10vw #000;

		&.slide-enter-active {
			animation: ${slideRight} 250ms ease-out both;
		}
		&.slide-exit-active {
			animation: ${slideLeft} 200ms ease-in both;
		}
	}
`;
const MobileMenuSidebarStyles = `
	min-height: 100%;
	overflow-y: scroll;
`;
const MobileMenuRestStyles = `
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 119;
	width: 100%;
	height: 100%;
`;
const { CLOSE, SEND } = GENERAL_ACTIONS, { SIDEBAR } = EVENT_CATEGORIES, { EVENT } = HIT_TYPES;
const styles = Object.assign({}, theme.logoBackground);
class MobileMenu extends React.Component {
    constructor() {
        super(...arguments);
        this.getUserStash = () => { };
        this.disableMobileMenu = () => {
            const pathname = window.location.pathname;
            if (window.ga) {
                window.ga(SEND, {
                    hitType: EVENT,
                    eventCategory: SIDEBAR,
                    eventAction: CLOSE,
                    eventLabel: pathname
                });
            }
            sendClevertapEvent(SIDEBAR, {
                action: CLOSE,
                url: pathname
            });
            const citySelector = document.querySelector("#city-selector");
            citySelector && citySelector.classList.remove("active");
            this.props.toggleMobileMenu();
        };
    }
    componentDidMount() {
        this.getUserStash();
    }
    componentDidUpdate() {
        this.getUserStash();
    }
    render() {
        const props = this.props;
        return (<CSSTransition in={props.showMobileMenu} timeout={400} classNames={"slide"} unmountOnExit>
				<Fragment>
					<div css={[styles, css(MobileMenuOverlayStyles)]} className={"active"}>
						<div css={css(MobileMenuSidebarStyles)}>
							<div style={{ padding: "20px" }}>
								<MobileMenuHeader disableMobileMenu={this.disableMobileMenu} selectedCity={props.selectedCity}/>
								<MobileMenuProfile isLoggedIn={props.isLoggedIn} disableMobileMenu={this.disableMobileMenu} login={props.login} handleCitySelectorClick={this.handleCitySelectorClick} selectedCity={props.selectedCity} stashes={props.stashes}/>
							</div>
							<MobileMenuFooter disableMobileMenu={this.disableMobileMenu} isLoggedIn={props.isLoggedIn} hideFooter={!props.isMobileMenuShowing}/>
						</div>
					</div>
					<div css={css(MobileMenuRestStyles)} onClick={this.disableMobileMenu}/>
				</Fragment>
			</CSSTransition>);
    }
}
export default MobileMenu;
