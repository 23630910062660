import React from "react";
export class PaytmLoginIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9192 10.9556C23.7014 10.3327 23.1078 9.88596 22.4101 9.88596H22.3955C21.9426 9.88596 21.5338 10.0744 21.2426 10.3767C20.9525 10.0744 20.5437 9.88596 20.0909 9.88596H20.0762C19.6772 9.88596 19.3125 10.0316 19.0334 10.2727V10.1503C19.0236 10.0279 18.9233 9.93124 18.7984 9.93124H17.7288C17.5978 9.93124 17.4926 10.0377 17.4926 10.1675V15.9785C17.4926 16.1094 17.5978 16.2147 17.7288 16.2147H18.7984C18.9184 16.2147 19.0163 16.1254 19.0322 16.0103L19.031 11.8405C19.031 11.8258 19.0322 11.8124 19.0334 11.7977C19.0506 11.6116 19.1864 11.4587 19.4018 11.4403H19.4422H19.5548H19.6001C19.6894 11.4476 19.7653 11.4807 19.8253 11.5272C19.9183 11.6019 19.9697 11.7157 19.9697 11.8405L19.9746 15.9895C19.9746 16.1217 20.0799 16.2269 20.2108 16.2269H21.2805C21.4066 16.2269 21.5094 16.1278 21.5155 16.0017V11.8356C21.5143 11.6973 21.5779 11.5737 21.6893 11.5003C21.7444 11.4648 21.8092 11.4415 21.8851 11.4354H21.9255H22.0381H22.0834C22.3135 11.455 22.4542 11.63 22.453 11.8356L22.4579 15.9785C22.4579 16.1094 22.5644 16.2159 22.6941 16.2159H23.7638C23.8947 16.2159 24 16.1094 24 15.9785V11.5223C24 11.2188 23.9657 11.0878 23.9192 10.9556" fill="#00BFF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6779 9.94819H16.0659V8.95684V8.95439C16.0659 8.83445 15.9692 8.73898 15.8505 8.73898C15.8358 8.73898 15.8224 8.74021 15.8101 8.74265C15.1309 8.92869 15.2667 9.86863 14.0281 9.94819H14.0012H13.9082C13.8911 9.94819 13.8727 9.95064 13.8568 9.95431H13.8556V9.95431C13.7503 9.97756 13.672 10.073 13.672 10.1844V11.2541C13.672 11.385 13.7773 11.4915 13.9082 11.4915H14.5544L14.5532 16.0273C14.5532 16.1558 14.6585 16.261 14.787 16.261H15.8444C15.9729 16.261 16.0782 16.1558 16.0782 16.0273V11.4915H16.6779C16.8076 11.4915 16.9141 11.385 16.9141 11.2541V10.1844C16.9141 10.0547 16.8076 9.94819 16.6779 9.94819" fill="#00BFF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8296 9.94884H11.7599C11.6302 9.94884 11.5237 10.0553 11.5237 10.185V12.3978C11.5213 12.5337 11.4099 12.6438 11.274 12.6438H10.8249C10.6866 12.6438 10.5752 12.5312 10.5752 12.3929L10.5703 10.185C10.5703 10.0553 10.4638 9.94884 10.3329 9.94884H9.26441C9.13345 9.94884 9.0282 10.0553 9.0282 10.185V12.6096C9.0282 13.5312 9.6842 14.1872 10.6058 14.1872C10.6058 14.1872 11.2973 14.1872 11.3181 14.1908C11.4429 14.2055 11.5396 14.3096 11.5396 14.4381C11.5396 14.5654 11.4454 14.6694 11.3218 14.6853C11.3157 14.6865 11.3095 14.6877 11.3034 14.689L9.73928 14.6951C9.60832 14.6951 9.50307 14.8003 9.50307 14.9313V15.9998C9.50307 16.1319 9.60832 16.2372 9.73928 16.2372H11.4882C12.4098 16.2372 13.0658 15.5812 13.0658 14.6596V10.185C13.0658 10.0553 12.9606 9.94884 12.8296 9.94884" fill="#24346E"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.47614 11.9059V12.4052V12.5656C2.47614 12.7039 2.36354 12.8165 2.22524 12.8165L1.54598 12.8177V11.4947H2.22524C2.36354 11.4947 2.47614 11.6073 2.47614 11.7443V11.9059ZM2.57017 9.94534H0.231315C0.104031 9.94534 0 10.0494 0 10.1779V11.2255C0 11.2267 0 11.2292 0 11.2292C0 11.2365 0 11.2414 0 11.2476V14.6084V15.9901C0 16.1186 0.0966874 16.2227 0.215405 16.2263H0.236211H1.30467C1.43562 16.2263 1.5421 16.1211 1.5421 15.9901L1.54577 14.3648H2.57017C3.42567 14.3648 4.02293 13.77 4.02293 12.9096V11.4018C4.02293 10.5426 3.42567 9.94534 2.57017 9.94534V9.94534Z" fill="#24346E"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9145 14.4189V14.5865C6.9145 14.5988 6.91328 14.6122 6.91083 14.6257C6.90838 14.6379 6.90471 14.649 6.89981 14.66C6.86677 14.753 6.77375 14.8215 6.66238 14.8215H6.21688C6.07858 14.8215 5.96599 14.7163 5.96599 14.5865V14.3846L5.96476 14.3772L5.96599 13.8387V13.7812V13.6723V13.6711C5.96599 13.5413 6.07858 13.4361 6.21688 13.4361H6.66238C6.8019 13.4361 6.9145 13.5413 6.9145 13.6723V14.4189ZM6.74427 9.95303H5.25969C5.12873 9.95303 5.02225 10.0534 5.02225 10.1746V10.5907L5.02348 10.5992C5.02348 10.6017 5.02225 10.6041 5.02225 10.6041V11.1781C5.02225 11.3079 5.13485 11.4131 5.27437 11.4131H6.68797C6.79934 11.4303 6.88746 11.511 6.8997 11.6383V11.7766C6.88746 11.8978 6.80057 11.9871 6.69286 11.9957H5.99402C5.06264 11.9957 4.39929 12.615 4.39929 13.4827V14.6956V14.7274C4.39929 15.5903 4.96963 16.2047 5.89366 16.2047H7.83353C8.18234 16.2047 8.46383 15.9415 8.46383 15.616V11.5588C8.46383 10.5735 7.95714 9.95303 6.74427 9.95303V9.95303Z" fill="#24346E"/>
            </svg>);
    }
}
