import React from "react";
import { connect } from "react-redux";
import { getUserDisplayName, getUserEmail, isUserLoggedIn } from "../selectors/index";
import UserInfoMobile from "../components/header/UserInfoMobile";
const mapStateToProps = (state, props) => ({
    displayName: getUserDisplayName(state),
    isLoggedIn: isUserLoggedIn(state),
    email: getUserEmail(state)
});
const UserInfoMobileWithSubscription = connect(mapStateToProps)(UserInfoMobile);
export default UserInfoMobileWithSubscription;
