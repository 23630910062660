import { connect } from "react-redux";
import MobileMenu from "../components/header/MobileMenu";
import { getSelectedCityName, getCitiesGroupedByPinned, getShouldShowCitySelector, getGroupDetails, getMobileMenuShowing, shouldShowMobileMenu, getIsSelectedCityInferred, isUserLoggedIn } from "../selectors/index";
import { toggleCitySelector, fetchStashes } from "../actions/index";
import { getUserId } from "../selectors/user";
import { getStashes } from "../selectors/stashes";
const mapStateToProps = (state) => ({
    isMobileMenuShowing: getMobileMenuShowing(state),
    groupDetails: getGroupDetails(state),
    cities: getCitiesGroupedByPinned(state),
    selectedCity: getSelectedCityName(state),
    shouldShowMobileMenu: shouldShowMobileMenu(state),
    isSelectedCityInferred: getIsSelectedCityInferred(state),
    shouldShowCitySelector: getShouldShowCitySelector(state),
    isLoggedIn: isUserLoggedIn(state),
    stashes: getStashes(state),
    userId: getUserId(state)
});
const mapDispatchToProps = (dispatch) => ({
    fetchStashes: (_id) => dispatch(fetchStashes(_id)),
    toggleCitySelector: (shouldShowCitySelector) => dispatch(toggleCitySelector(shouldShowCitySelector))
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
