/** @jsx jsx */
import { jsx, css } from "@emotion/core";
// @ts-ignore
import { Fragment } from "react";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
const AddFlex = css({
    display: "flex",
    alignItems: "center"
});
const FooterContainer = css({
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "20px"
});
const FooterLine = css({
    border: "solid 0.5px #dddfe2"
});
const FooterElement = css({
    width: "100%",
    height: "3em"
});
const FooterText = css({
    fontSize: "14px",
    color: "#676465"
});
const MobileMenuFooter = (props) => {
    return (<Fragment>
			<div className={`footer-line`} css={FooterLine}/>
			<div className={`footer-container-menu`} css={[AddFlex, FooterContainer]}>
				{props.isLoggedIn && (<div className={`footer-edit`} css={FooterElement} onClick={props.disableMobileMenu}>
						<InsiderLink className={`footer-text`} href={"/users/me/profile"} css={FooterText}>
							Edit Profile
						</InsiderLink>
					</div>)}
				<div className={`footer-about-menu`} css={FooterElement} onClick={props.disableMobileMenu}>
					<InsiderLink href={"/about"}>
						<div className={`footer-text`} css={FooterText}>
							About
						</div>
					</InsiderLink>
				</div>
				<div className={`footer-help-menu`} css={FooterElement} onClick={props.disableMobileMenu}>
					<InsiderLink href={"/contact"}>
						<div className={`footer-text`} css={FooterText}>
							Help
						</div>
					</InsiderLink>
				</div>
				{props.isLoggedIn && (<div className={`footer-sign-out-menu`} css={FooterElement}>
						<a className={`footer-text`} href="/users/logout" css={FooterText}>
							Sign Out
						</a>
					</div>)}
			</div>
		</Fragment>);
};
export default MobileMenuFooter;
