import { connect } from "react-redux";
import { setModalScreen, setModalType, setUserLoginData } from "../actions";
import { MobileMenuLogin } from "../components/header/MobileMenuLogin";
import { getDisableSocialLoginFlag, isSamsungDevice } from "../selectors";
const mapStateToProps = (state) => ({
    disableSocialLogin: getDisableSocialLoginFlag(state),
    isSamsungDevice: isSamsungDevice(state)
});
const mapDispatchToProps = (dispatch) => ({
    setUserLoginData: (userData) => dispatch(setUserLoginData(userData)),
    setModalType: (payload) => dispatch(setModalType(payload)),
    setModalScreen: (screen) => dispatch(setModalScreen(screen))
});
const MobileMenuLoginWithSubscription = connect(mapStateToProps, mapDispatchToProps)(MobileMenuLogin);
export default MobileMenuLoginWithSubscription;
