/** @jsx jsx */
import React, { Fragment } from "react";
import { jsx, css } from "@emotion/core";
import UserInfoMobile from "../../containers/UserInfoMobileWithSubscription";
import { capitalizeFirstLetter } from "../../helpers/constants";
import { LocationIcon } from "../ui/icons/LocationIcon";
import { TicketIcon } from "../ui/icons/TicketIcon";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
import { generateCitySelectorLink } from "../../helpers/linkGenerators";
import MobileMenuLogin from "../../containers/MobileMenuLoginWithSubscriptions";
//Object Styles
const AddFlex = css({
    display: "flex",
    alignItems: "center"
});
const StashCityContainer = css({
    display: "flex",
    justifyContent: "space-between",
    margin: "12px 0 24px"
});
// @ts-ignore
const UserNameStyle = css({
    fontSize: "25px",
    fontWeight: "900",
    lineHeight: "1.12",
    letterSpacing: "-0.2px",
    color: "#373435",
    margin: "0"
});
const CityChangeStyle = css({
    height: "50px",
    justifyContent: "baseline",
    flexFlow: "column",
    alignItems: "baseline",
    marginBottom: "8px"
});
const ChangeCity = css({
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.29",
    letterSpacing: "-0.3px",
    color: "#3695d8"
});
const StashButton = css({
    width: "48%",
    height: "36px",
    borderRadius: "6px",
    border: "solid 0.5px #dddfe2",
    backgroundColor: "#fafafa",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 12px"
});
const StashButtonText = css({
    fontSize: "14px",
    lineHeight: "1.31",
    color: "#676465",
    textOverflow: "ellipsis",
    "& .stash": {
        fontWeight: "unset"
    }
});
// @ts-ignore
const StashButtonTextBold = css({
    fontSize: "14px",
    fontWeight: "900",
    lineHeight: "1.31",
    color: "#373435"
});
const CityButton = css({
    alignItems: "center",
    padding: "0vw 4vw 0vw 4vw",
    justifyContent: "flex-start"
});
const CityIcon = css({
    marginRight: "6px"
});
const Body = css({
    height: "fitContent",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
    padding: "0"
});
const BodyContainer = css({
    height: "35px",
    margin: "10px 0"
});
const BodyIcon = css({
    marginRight: "10px"
});
const BodyText = css({
    fontSize: "16px",
    color: "#676465"
});
const BodyAnchorStyle = css({
    textDecoration: "none",
    width: "100%",
    height: "100%"
});
const MobileMenuBody = ({ children }) => {
    return (<ul className={`mobile-menu-body`} css={Body}>
			{children}
		</ul>);
};
const MobileMenuBodyElement = ({ href, onClick, icon, text, children }) => {
    return (<li css={[BodyContainer, AddFlex]}>
			<InsiderLink href={href} onClick={onClick} css={[BodyAnchorStyle, AddFlex]}>
				<div css={BodyIcon}>{icon}</div>
				<div css={BodyText}>{text}</div>
			</InsiderLink>
			{children}
		</li>);
};
const MobileMenuProfileNotLoggedInClass = (props) => {
    return (<Fragment>
			<div className={`mobile-menu-city-change-container`} css={[AddFlex, CityChangeStyle]}>
				<h1 className={`mobile-menu-city-name`} css={UserNameStyle}>
					{" "}
					{capitalizeFirstLetter(props.selectedCity)}
				</h1>
				<InsiderLink className={`change-city`} css={ChangeCity} href={generateCitySelectorLink()} onClick={props.disableMobileMenu}>
					Change City
				</InsiderLink>
			</div>
			<MobileMenuLogin disableMobileMenu={props.disableMobileMenu}/>
		</Fragment>);
};
const getInsiderStash = (stashes) => {
    return (stashes.size > 0 &&
        stashes
            .filter((s) => s.get(`name`) === `Wallet`)
            .first()
            .get(`balance`));
};
export class MobileMenuProfile extends React.Component {
    render() {
        const { disableMobileMenu, login, isLoggedIn, selectedCity } = this.props;
        if (!isLoggedIn) {
            return (<MobileMenuProfileNotLoggedInClass disableMobileMenu={disableMobileMenu} login={login} isLoggedIn={isLoggedIn} selectedCity={selectedCity}/>);
        }
        const { props } = this;
        return (<Fragment>
				<div className={`mobile-menu-user-details-container`} css={css ``}>
					<UserInfoMobile disableMobileMenu={props.disableMobileMenu}/>
				</div>
				<div className={`stash-city-container`} css={StashCityContainer}>
					<div className={`stash-button`} css={StashButton}>
						<div className={`stash-button-text`} css={[AddFlex, StashButtonText]}>
							<InsiderLink href={`/users/me/wallet`} onClick={props.disableMobileMenu}>
								<span css={StashButtonTextBold}>₹{getInsiderStash(props.stashes)}</span>
								<span className={`stash`} css={StashButtonText}>
									{" "}
									balance
								</span>
							</InsiderLink>
						</div>
					</div>

					<div className={`city-button`} css={[StashButton, CityButton]}>
						<div className={`city-icon`} css={CityIcon}>
							<LocationIcon />
						</div>
						<InsiderLink href={generateCitySelectorLink()} onClick={props.disableMobileMenu}>
							<div className={`city-button-text`} css={StashButtonText}>
								{capitalizeFirstLetter(props.selectedCity)}
							</div>
						</InsiderLink>
					</div>
				</div>
				<MobileMenuBody>
					<MobileMenuBodyElement href={`/users/me/tickets`} onClick={props.disableMobileMenu} text={`Tickets`} icon={<TicketIcon />}/>
					{/* <MobileMenuBodyElement
                href={`/users/me/favourites`}
                onClick={props.disableMobileMenu}
                text={`Favourites`}
                icon={<MobileMenuHeartIcon />}
            /> */}
				</MobileMenuBody>
			</Fragment>);
    }
}
